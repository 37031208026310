<template>
  <RevContainer>
    <RevContentBlock
      :button-label="i18n(translations.noResultButtonLabel)"
      button-variant="primary"
      :image-props
      :title="i18n(translations.noResultTitle)"
      title-variant="title1"
      :to="resolveLocalizedRoute({ name: HOME })"
      @click="handleClick"
    >
      <p>
        {{ i18n(translations.noResultDescription) }}
      </p>
    </RevContentBlock>
  </RevContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useBreakpoint } from '@backmarket/utils/composables/useBreakpoint'
import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'
import { RevContainer } from '@ds/components/Container'
import { RevContentBlock } from '@ds/components/ContentBlock'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'

import { HOME } from '../../../home/route-names'

import translations from './NoResult.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const breakpoint = useBreakpoint()
const { trackClick } = useTracking()

const isSmallScreen = computed(() => breakpoint.value < Breakpoint.LG)

const imageProps = computed(() => {
  const smallScreenImage = {
    alt: '',
    height: 177,
    src: '/img/favorites/no-result.svg',
    width: 179,
  }

  const largeScreenImage = {
    alt: '',
    height: 252,
    src: '/img/favorites/no-result.svg',
    width: 256,
  }

  return isSmallScreen.value ? smallScreenImage : largeScreenImage
})

function handleClick() {
  trackClick({
    name: 'find_favs',
    pageType: 'my_favorites',
    zone: 'my_favorites',
  })
}
</script>
